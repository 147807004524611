import { createRouter, createWebHashHistory } from 'vue-router'
import Layout from 'views/layout/index.vue'
import { constantRouterComponents } from './white'

export const routes = [
  {
    path: '/',
    redirect: '/login',
    hidden: true,
    component: () => import('views/login/index.vue'),
    
  },
  {
    path: '/login',
    name: 'login',
    hidden: true,
    component: () => import('views/login/index.vue')
  },
  {
    path: '/404',
    name: '404',
    hidden: true,
    component: () => import('views/login/404.vue')
  },
  {
    path: '/layout',
    name: 'Layout',
    component: Layout,
    children: [
      {
        path: '/home',
        name: 'home',
        title: '首页',
        component: () => import('views/home/index.vue')
      }
    ]
  }
]

export function getMenusRoute() {
  let list = []
  let menus = JSON.parse(localStorage.getItem('menus'))
  menus && menus.forEach((item) => {
    if(item.path != '/home') {
      let obj = {
        path: item.path,
        name: item.name,
        full_path: item.full_path,
        component: (constantRouterComponents[item.name]) || (() => import(`${item.full_path}`))
      }
      list.push(obj)
    }
  })
  return list
}

const router = createRouter({
  history: createWebHashHistory(),
  routes
})


let registerRouteFresh = true
router.beforeEach((to, from, next) => {
  if (to.path !== '/login' && to.path !== '/chooseUser' && to.path !== '/homeLogin' && to.path !== '/backstage') {
    if (localStorage.getItem('token')) {
      let list = getMenusRoute()
      if (registerRouteFresh && list.length) {
        list.forEach((itemRouter) => {
          router.addRoute('Layout', itemRouter)
        })
        router.addRoute({
          path: '/:pathMatch(.*)',
          redirect: '/404'
        })
        next(to)
        registerRouteFresh = false
      } else {
        next()
      }
    } else {
      next('/login')
    }
  } else {
    next()
  }
})

export default router
